<template lang="html">
  <div class="Table">
    <simplebar class="TableScrollable">
      <div class="TableScrollable__wrapper">
        <TableHeader
          @sortChanged="orderBy"
          :ordered-by="orderedBy"
          :columns="table.header"
        />

        <main class="TableBody">
          <TablePlayer
            v-for="item in chunkedList[this.listQuery.page - 1]"
            :key="item.id"
            :player="item"
          ></TablePlayer>
        </main>
      </div>
    </simplebar>

    <footer>
      <Pagination
        :total="this.total"
        :page="this.listQuery.page"
        :limit="this.listQuery.limit"
        @pagination="handlePagination"
        :pagination-history="paginationHistory"
      ></Pagination>
    </footer>
  </div>
</template>

<script>
  import TablePlayer from '@/partials/Players/TablePlayer'

  // Mixins
  import TableMixin from '@/mixins/TableMixin'

  export default {
    name: 'Table',
    mixins: [TableMixin],
    components: {
      TablePlayer,
    },
    data: function() {
      return {
        table: {
          header: [
            {
              label: 'Name',
              sorted: true,
              sortProp: 'name',
              size: 'flex',
            },
            {
              label: 'Rating',
              size: 'short',
              sorted: true,
              sortProp: 'average_rating.0.rating',
            },
            {
              label: 'Preferred Positions',
              size: 'medium',
            },
            {
              label: 'Notes',
              sorted: true,
              sortProp: 'player_notes',
              size: 'short',
            },
            {
              label: 'Birthday',
              sorted: true,
              sortProp: 'birthday',
              size: 'short',
            },
            {
              label: '',
              size: 'extend',
            },
          ],
        },
      }
    },
  }
</script>
