<template lang="html">
  <article class="Note">
    <div class="Note__author" v-if="note.author">
      <div class="User User--reversed">
        <div class="ml-8 flex-col">
          <span class="text-bold">{{ note.author.name }}</span>
          <span class="text-sm">{{ note.created_at | moment('DD/MM/YYYY')}}</span>
        </div>
        <profile-pic :src="note.author.photo_url" :size="40" :alt="note.author.name" circle/>
      </div>
      <hr class="divider">
      <div class="Note__actions" v-if="note.author_id == this.$store.getters.getUserId">
        <action icon="edit" reversed @actionClicked="handleActionEdit"></action>
        <action icon="rubbish-bin" reversed @actionClicked="handleActionRemove"></action>
      </div>
    </div>
    <div class="Note__content">{{ note.content }}</div>
    <vue-toggle-slide :active="editNote" class="pt-24" v-if="note.author_id == this.$store.getters.getUserId">
      <div class="Card Card--limited" >
        <NoteForm @noteAdded="handleAddedNote()" :player="{id: note.player_id}" :note="note"/>
      </div>
    </vue-toggle-slide>
  </article>
</template>

<script>
import NoteForm from '@/components/Forms/NoteForm'
export default {
  name: 'Note',
  components: {NoteForm},
  props: {
    note: {
      type: Object,
      required: true,
    }
  },
  data: function() {
    return {
      editNote: false,
    }
  },
  methods: {
    handleActionEdit() {
      this.editNote = !this.editNote
    },
    handleAddedNote() {
      this.editNote = false
    },
    handleActionRemove() {
      this.$store.dispatch('players/REMOVE_NOTE', {
        player: this.note.player_id,
        id: this.note.id,
      })
    }
  }
}
</script>
