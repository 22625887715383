<template>
  <div class="Dashboard">
    <Breadcrumbs />
    <dashboard-top>
      <screen-title
        :title="this.$store.state.user.team.name"
        action="players/new"
        :actionPermission="userIs('admin')"
        button="Add New players"
      ></screen-title>
    </dashboard-top>

    <div class="container-full">
      <section-title title="All Registered Players">
        <Search @searched="handleSearch" />
        <Dropdown :items="sortOptions" @dropdown-item-click="handleSortChange">
          <action icon="sort"></action>
        </Dropdown>
      </section-title>

      <fade-transition mode="out-in">
        <TableLoader v-if="!loaded" />

        <Table
          :items="players"
          :search-query="searchQuery"
          :key="players.length"
          :sort="sortPlayersBy"
          v-if="loaded && players.length > 0"
          paginationHistory
        />

        <empty-message v-if="loaded && players.length == 0"
          >No players found</empty-message
        >
      </fade-transition>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import TableLoader from '@/components/Loaders/TableLoader'
  import Table from '@/components/Tables/PlayersTable'

  import SearchMixin from '@/mixins/SearchMixin'

  export default {
    name: 'Players',
    mixins: [SearchMixin],
    data: function() {
      return {
        sortPlayersBy: {
          sortedBy: null,
          status: 0,
        },
        sortOptions: [
          { label: 'Sort By Name', value: 'name' },
          { label: 'Sort By Rating', value: 'average_rating.0.rating' },
          { label: 'Sort By Birthday', value: 'birthday' },
          { label: 'Reset sorting', value: 'reset' },
        ],
      }
    },
    components: {
      Table,
      TableLoader,
    },
    mounted() {
      this.$store.dispatch('players/GET_PLAYERS')
    },
    methods: {
      handleSortChange(payload) {
        let isReset = payload.value === 'reset'
        let status = isReset
          ? 0
          : payload.value !== this.sortPlayersBy.sortedBy
          ? 1
          : this.sortPlayersBy.status < 2
          ? 2
          : 0
        this.sortPlayersBy = {
          sortedBy: payload.value,
          status: status,
        }
      },
    },
    computed: {
      ...mapState({
        players: (state) => state.players.all,
        loaded: (state) => state.players.status.all == 'success',
      }),
    },
  }
</script>
