<template lang="html">
  <ValidationObserver v-slot="{ handleSubmit }" slim ref="noteForm">
    <form @submit.prevent="handleSubmit(handleDivisionSubmit)" class="Form">
      <formly-form
        :form="form"
        :model="model"
        :fields="fields"
        tag="div"
        class="Form__group"
      ></formly-form>
      <button class="btn btn--outlined">{{ buttonContent }}</button>
    </form>
  </ValidationObserver>
</template>

<script>
  export default {
    name: 'NoteForm',
    props: {
      player: {
        type: [Object, Boolean],
        required: true,
      },
      note: {
        type: [Object, Boolean],
        required: false,
      },
    },
    data: function() {
      return {
        model: {
          content: null,
        },
        form: {},
        fields: [
          {
            key: 'content',
            type: 'textarea',
            rules: 'required',
            templateOptions: {
              label: 'Note',
              layout: 'full',
            },
          },
        ],
      }
    },
    created() {
      if (this.note) {
        this.setInitialData()
      }
    },
    methods: {
      handleDivisionSubmit() {
        let payload = {
          player: this.player.id,
          content: this.model.content,
        }

        if (this.note) {
          this.$store
            .dispatch('players/UPDATE_NOTE', {
              id: this.note.id,
              ...payload,
            })
            .then(() => {
              this.goBack()
            })
        } else {
          this.$store.dispatch('players/CREATE_NOTE', payload).then(() => {
            this.sanatizeForm()
            this.goBack()
          })
        }
      },
      sanatizeForm() {
        this.model.content = ''
        this.$refs.noteForm.reset()
      },

      setInitialData() {
        this.model.content = this.note.content
      },

      goBack() {
        this.$emit('noteAdded')
      },
    },
    computed: {
      buttonContent() {
        return this.note ? 'Update note' : 'Save note'
      },
    },
    watch: {
      note() {
        if (this.note) {
          this.setInitialData()
        }
      },
    },
  }
</script>
