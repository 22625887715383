<template>
  <content-loader
    :width="1900"
    :height="550"
    :speed="2"
    viewBox="0 0 1920 550"
    :primaryColor="primaryColor"
    secondaryColor="#D1D1D1"
  >
  <rect x="10" y="10" rx="3" ry="3" width="230" height="20" />
  <rect x="290" y="10" rx="3" ry="3" width="490" height="20" />
  <rect x="1370" y="10" rx="3" ry="3" width="190" height="20" />
  <rect x="1600" y="10" rx="3" ry="3" width="130" height="20" />
  <rect x="1770" y="10" rx="3" ry="3" width="130" height="20" />
  <rect x="10" y="40" rx="3" ry="3" width="100%" height="90" />
  <rect x="10" y="140" rx="3" ry="3" width="100%" height="90" />
  <rect x="10" y="240" rx="3" ry="3" width="100%" height="90" />
  <rect x="10" y="340" rx="5" ry="5" width="100%" height="90" />
  </content-loader>
</template>

<script>
  import { ContentLoader } from "vue-content-loader"
  export default {
    components: { ContentLoader },
    props: {
      primaryColor: {
        type: String,
        default: '#fff',
      }
    }
  }
</script>
