<template lang="html">
  <div class="TablePlayer">
    <div
      :class="[
        'TablePlayer__table',
        detailsVisible ? 'TablePlayer__table--active' : '',
      ]"
    >
      <router-link
        :to="`players/${player.id}`"
        class="TableColumn TableColumn--flex clickable md-w-90"
      >
        <profile-pic :src="player.photo_url" :alt="player.name" :size="40" />
        <div class="ml-8">{{ player.name }}</div>
      </router-link>
      <div class="TableColumn TableColumn--short">
        <rating
          :rating="
            player.average_rating[0] ? player.average_rating[0].rating : null
          "
        ></rating>
      </div>
      <div class="TableColumn TableColumn--medium">
        <span v-if="player.preffered_positions" class="TablePlayer__positions">
          <tag-item
            v-for="position in player.preffered_positions"
            :key="position.id"
            >{{ position.short_name }}</tag-item
          >
        </span>
        <span v-else>
          Player haven't defined preffered positions.
        </span>
      </div>
      <div class="TableColumn TableColumn--short">
        <action
          icon="files"
          grey
          v-if="hasNotes"
          @click.native="toggleDetails"
        ></action>
      </div>
      <div class="TableColumn TableColumn--short">
        {{ player.birthday | moment('D/M/YYYY') }}
      </div>
      <div
        :class="['TableColumn TableColumn--extend md-w-10']"
        @click="toggleDetails"
      >
        <svg-icon icon="more"></svg-icon>
      </div>
    </div>
    <transition name="slide">
      <div v-if="detailsVisible">
        <section class="TablePlayer__content">
          <div class="TablePlayer__content__block" v-if="hasNotes">
            <h1 class="text-md text-bold">Selector's notes</h1>
            <Note
              v-for="note in player.player_notes"
              :key="note.id"
              :note="note"
            />
          </div>
          <div class="TablePlayer__content__block" v-else>
            <h1 class="text-md text-bold">Selector's notes</h1>
            Player doesn't have any notes
          </div>
          <div class="TablePlayer__content__block" v-if="hasHistory">
            <h1 class="text-md text-bold">Game History</h1>
            <div
              class="Note"
              v-for="note in player.player_notes"
              :key="note.id"
            >
              <div class="Note__content" v-html="note.content"></div>
            </div>
          </div>
          <div class="TablePlayer__content__block" v-else>
            <h1 class="text-md text-bold">Game History</h1>
            Player haven't played any games
          </div>
        </section>
      </div>
    </transition>
  </div>
</template>

<script>
  import Note from '@/partials/Note'
  export default {
    name: 'TablePlayer',
    components: { Note },
    props: {
      player: {
        type: Object,
        required: true,
      },
    },
    data: function() {
      return {
        detailsVisible: false,
      }
    },
    methods: {
      toggleDetails() {
        this.detailsVisible = !this.detailsVisible
      },
    },
    computed: {
      hasNotes() {
        return this.player.player_notes && this.player.player_notes.length > 0
      },
      hasHistory() {
        return this.player.history && this.player.history.length > 0
      },
    },
  }
</script>
