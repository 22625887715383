<template lang="html">
  <div class="Search">
    <div class="Search__wrap" v-show="isSearchVisible">
      <input type="search" class="input Search__input" v-model='searchable' placeholder="Type to search" ref="search">
    </div>
    <action icon="search" @actionClicked="handleSearchSwitch" :active="isSearchVisible"></action>
  </div>
</template>

<script>
export default {
  name: 'Search',
  data() {
    return {
      isSearchVisible: false,
      searchable: '',
    }
  },
  methods: {
    handleSearchSwitch() {
      this.isSearchVisible = !this.isSearchVisible
      this.searchable = ''
      this.$nextTick(() => {
        this.$refs.search.focus()
      })
    }
  },
  watch: {
    searchable(val) {
      this.$emit('searched', val)
    }
  }
}
</script>

<style lang="scss">
@import '../styles/abstracts/include';

.Search {
  display: flex;
  position: relative;
  margin-right: 10px;
  &__wrap {
    margin-right: 10px;
    min-width: 240px;
    @include media('<tablet') {
      min-width: 140px;
    }
  }

  &__input {
    background-color: color(bg, 5);
    height: 40px;
    border: none;
    border-radius: 5px;
    padding: 0 8px;
  }
}
</style>
