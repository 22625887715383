import Search from '@/partials/Search';

const SearchMixin = {
  components: {
    Search,
  },
  data() {
    return {
      searchQuery: '',
    }
  },
  methods: {
    handleSearch(payload) {
      this.searchQuery = payload
    },
  }
}

export default SearchMixin
